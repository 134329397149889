<template>
    <div class="Backup full-height pa-4 d-flex flex-column ">
        <div v-if="showProgress" class="loaderOverlay full-height d-flex flex-column align-center justify-center pb-6">
            <div class="message pb-6">
                <p>{{activity}}</p>
            </div>
            <v-progress-linear
                color="disiIrisblue"
                :value=progress
                background-color="disiDarkblue"
                height="12"
                buffer-value="0"
                stream
            ></v-progress-linear>
        </div>
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <switch-on-off name="backupSettings-automaticBackup" :disabled="!checkUserRights('backupEdit')" :class="{'active': automaticBackup}" class="mr-4"/>
                        </v-col>
                        <v-col class="col-10">
                            <p>{{ $t('system.backup.automaticBackup') }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': !automaticBackup}">
                            <p>{{ $t('system.backup.backupInterval') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="backupSettings-interval" @onInputFocus="onInputFocus" onlyNumbers :disabled="!automaticBackup" label="system.backup.days"  :readonly="!checkUserRights('backupEdit')" :class="{'noAction': !checkUserRights('backupEdit')}" :rules="[rules.numeric, rules.autoBackup, rules.interval]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('system.backup.backupFolder') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="backupSettings.pathId" persistent width="500px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="showPathName"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details="auto"
                                        outlined
                                        class="is-modal"
                                        :class="{'noAction': !checkUserRights('backupEdit')}"
                                        :rules="[rules.autoBackup]"
                                        id="pathmodal"
                                    ></v-text-field>
                                </template>
                                <div>
                                    <div class="dialogcontainer pa-8">
                                        <p style="font-weight: 500">{{ $t('system.backup.selectPath') }}</p>
                                        <v-list>
                                            <v-list-item-group
                                                v-model="backupSettings.pathId"
                                                color="disiIrisblue"
                                                class="list mt-4 mb-8"
                                                id="pathmodal-list"
                                                mandatory
                                            >
                                                <v-list-item
                                                    v-for="(item) in this.pathlist"
                                                    :key="item.id"
                                                    :value="item.id"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                        <div class="button-container d-flex justify-space-between">
                                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                                            <v-btn elevation="0" class="content-btn small" @click="$refs.pathdialog.save(backupSettings.pathId)">{{ $t('footerButton.save') }}</v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-dialog>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="restoreBackup" :class="{'disabled': !checkUserRights('backupEdit')}" @footerButtonClick="goToRestore"></footer-button>
            <footer-button buttonFunction="createBackup" :successCheck="showBackupSuccess" :progress="showBackupProgress" :class="{'disabled': !checkUserRights('backupEdit')}" @footerButtonClick="checkForChanges"></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToSystem" :class="{'disabled': disableBack}"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress" :class="{'disabled': saveDisabled || !checkUserRights('backupEdit')}" @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import FooterButton from '@/components/FooterButton.vue'

import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Backup',
    components: {
        TextInput,
        SwitchOnOff,
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            showProgress: false,
            progress: 0,
            activity: '',
            disableBack: false,
            pathmodal: false,
            saveDisabled: true,
            showBackupProgress: false,
            showBackupSuccess: false,
            showSaveProgress: false,
            showSaveSuccess: false,
            originalBackup: {},
            rules: {
                autoBackup: v => {
                    if (this.automaticBackup){
                        if (v){
                            return !!v;
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
                interval: v => {
                    if (v){
                        return v < 367 || `${this.$t('validation.maximum')}: 366`;
                    } else return true;
                },
                numeric: v => {
                    if (v){
                        let numeric = /^[0-9]+$/;
                        return numeric.test(v) || `${this.$t('validation.pleaseEnterValidValue')}`;
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'backupSettings',
            'pathlist'
        ]),
        automaticBackup(){
            return this.$store.state.backupSettings.automaticBackup;
        },
        showPathName(){
            let path = this.pathlist.find(element => {
                return element.id == this.backupSettings.pathId;
            });
            if (path){
                return path.name;
            } else {
                return this.$t('general.unknown');
            }
        }
    },
    methods:{
        goToSystem(){
            this.$router.push('system');
        },
        goToRestore(){
            this.$router.push('backup-restore');
        },
        getProgress(){
            this.$store.dispatch('getAxiosNoSetter', '/admin/backups/progress')
                .then(response=>{
                    if (response.status === 200 && this.$router.currentRoute.name == 'Backup'){
                        if (response.data.progress < 100){
                            this.progress = response.data.progress;
                            this.activity = response.data.activity;
                            setTimeout(()=>{
                                this.getProgress();
                            }, 500)
                        } else{
                            this.$store.dispatch('getAxiosSetter', ['/admin/backups/list', 'backuplist']);
                            this.disableBack = false;
                            this.showProgress = false;
                            this.showBackupProgress = false;
                            this.showBackupSuccess = true;
                            setTimeout(()=>{
                                this.showBackupSuccess = false;
                            }, 1600)
                        }
                    } else {
                        this.showProgress = false;
                        this.showBackupProgress = false;
                        this.disableBack = false;
                    }
                })
        },
        checkForChanges(){
            if(this.saveDisabled){
                this.showBackupProgress = true;
                this.doBackup();
            } else {
                this.$confirm({message: this.$t('alerts.unsavedChanges'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                    .then(() => {
                        // continue
                        this.doBackup();
                    })
                    .catch(() => {
                        // cancel
                    });
            }
        },
        doBackup(){
            this.$store.dispatch('getAxiosNoSetter', '/admin/backups/create')
            .then(response=>{
                if (response.status === 200) {
                    this.showProgress = true;
                    this.disableBack = true;
                    this.getProgress();
                } else {
                    this.showProgress = false;
                    this.showBackupProgress = false;
                    this.disableBack = false;
                }
            })
        },
        save(){
            this.$refs.form.validate();
            if (this.$refs.form.validate()){
                this.showSaveProgress = true;
                let Backup = {}
                if (this.backupSettings.automaticBackup != this.originalBackup.automaticBackup){
                    Backup.automaticBackup = this.backupSettings.automaticBackup;
                }
                if (this.backupSettings.interval != this.originalBackup.interval && this.backupSettings.interval != null){
                    Backup.interval = parseInt(this.backupSettings.interval);
                }
                if (this.backupSettings.pathId != this.originalBackup.pathId && this.backupSettings.pathId != null){
                    Backup.pathId = this.backupSettings.pathId;
                }
                if (Object.keys(Backup).length === 0 && Backup.constructor === Object){
                    this.showSaveProgress = false;
                    this.showSaveSuccess = true;
                    this.watcher();
                    setTimeout(()=>{
                        this.saveDisabled = true;
                        this.showSaveSuccess = false;
                    }, 800)
                } else {
                    this.$store.dispatch('postAxiosNoSetter', ['admin/backups/settings', Backup])
                        .then(response=>{
                            this.showSaveProgress = false;
                            if (response.status === 200) {
                                this.showSaveSuccess = true;
                                this.watcher();
                                setTimeout(()=>{
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            }
                        })
                }
            }
        },
        watcher(){
            let automaticBackup = this.$watch('$store.state.backupSettings.automaticBackup', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let interval = this.$watch('$store.state.backupSettings.interval', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            let pathId = this.$watch('$store.state.backupSettings.pathId', function() {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function(){
                automaticBackup();
                interval();
                pathId();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/share/pathlist', 'pathlist']);
        this.$store.dispatch('getAxiosSetter', ['/admin/backups/settings', 'backupSettings'])
            .then(response=> {
                if (response.status === 200) {
                    this.watcher();
                }
            })
    },
    beforeRouteLeave (to, from, next) {
        if(this.showProgress){
            this.$confirm({message: this.$t('alerts.backupInProgress'), show: true, okButton: true })
                .then(() => {
                    // continue
                })
                .catch(() => {
                    // cancel
                });
        } else if(!this.saveDisabled && !this.showProgress) {
            this.$confirm({message: this.$t('alerts.unsavedChanges'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        } else {
            next()
        }
    },
}
</script>
<style scoped lang="scss">
</style>